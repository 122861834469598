body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  src: url("./fonts/Pretendard-Thin.otf") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  src: url("./fonts/Pretendard-ExtraLight.otf") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  src: url("./fonts/Pretendard-Light.otf") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  src: url("./fonts/Pretendard-Regular.otf") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  src: url("./fonts/Pretendard-Medium.otf") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  src: url("./fonts/Pretendard-SemiBold.otf") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  src: url("./fonts/Pretendard-Bold.otf") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  src: url("./fonts/Pretendard-ExtraBold.otf") format("woff2");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  src: url("./fonts/Pretendard-Black.otf") format("woff2");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
